import React, { useState } from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { FluidImage } from '../components/fluidImage';

const Anwaelte = ({}) => {
    const [opened, setOpen] = useState([]);

    const toggle = (e, id) => {
        setOpen(state => ({
            [id]: !state[id],
        }));
        e.preventDefault();
    };

    const isOpen = id => {
        return opened[id] || false;
    };

    const anwaelte = useAnwaltData();

    return (
        <Layout>
            <SEO title="Anwälte" />
            <section className="page-title">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h1>Anwälte</h1>
                        </div>
                    </div>
                </div>
            </section>
            <section className="team">
                <div className="container">
                    {anwaelte.map(anwalt => (
                        <div key={anwalt.name} className="row team-member">
                            <div className={`col-12 name-cont ${anwalt.position_align === 'right' ? 'col-lg-4' : ''}`}>
                                <span>Portrait</span>
                                <div className="name">
                                    <h2>{anwalt.name}</h2>
                                </div>
                            </div>
                            <div className="col-12 col-lg-8 img-and-info">
                                {anwalt.position_align === 'left' && <div className="col-lg-4 col-hidden" />}
                                <div className="info">
                                    <FluidImage name={anwalt.image} />
                                    <div
                                        className={`info-box d-flex flex-column justify-content-around ${anwalt.name === 'Dr. Bettina Pauly' ? 'bettina' : ''}`}
                                    >
                                        <p>Schwerpunkte</p>
                                        <div className="tags d-flex align-items-center justify-content-between flex-wrap">
                                            {anwalt.competents.map(competent => (
                                                <React.Fragment key={competent.path}>
                                                    {null !== competent.path ? (
                                                        <Link to={competent.path} className="tag d-flex align-items-center justify-content-center">
                                                            {competent.title}
                                                        </Link>
                                                    ) : (
                                                        <div className="tag d-flex align-items-center justify-content-center">{competent.title}</div>
                                                    )}
                                                </React.Fragment>
                                            ))}
                                        </div>
                                        <div className="more-button-container">
                                            <button className="text-uppercase" onClick={e => toggle(e, anwalt.id)}>
                                                <span className="plus" />
                                                <span className={`minus ${isOpen(anwalt.id) && 'd-none'}`} />
                                                Vita
                                            </button>
                                        </div>
                                        <div className={`member-read-more ${isOpen(anwalt.id) && 'open'}`}>
                                            <ul>
                                                {anwalt.vita.map(vitae => (
                                                    <li key={vitae}>
                                                        <p className="western">{vitae}</p>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </section>
            <section className="legal-emloyee">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h2>Juristischer Mitarbeiter</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <FluidImage name="juergenschneider.jpg" />
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="text-box">
                                <p>
                                    Jürgen Schneider
                                    <br />
                                    seit 2016 bei Bauer &amp; Dr. Pauly
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="team-kontakt">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h2>Unser Sekretariat</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="text-box">
                                <p>
                                    Als Dreh- und Angelpunkt der Organisation der Anwaltskanzlei sorgt das Sekretariat für einen reibungslosen und ruhigen
                                    Ablauf. Stets freundlich und kompetent fungiert es als erste Anlaufstelle für Ihre Anliegen und beantwortet auch gerne Ihre
                                    Fragen oder vermittelt Sie an den zuständigen Rechtsanwalt weiter.
                                </p>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="kontakt-box background">
                                <div className="img-box">
                                    <FluidImage name="secretary.jpg" />
                                </div>
                                <Link to="/kontakt" className="text-uppercase">
                                    Jetzt kontaktieren
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default Anwaelte;

const useAnwaltData = () => {
    const data = useStaticQuery(
        graphql`
            query {
                anwaelte: allMarkdownRemark(limit: 1000, sort: { fields: frontmatter___id }, filter: { fileAbsolutePath: { regex: "/anwaelte/" } }) {
                    edges {
                        node {
                            frontmatter {
                                name
                                competents
                                vita
                                position_align
                                id
                                image
                            }
                        }
                    }
                }
                competents: allMarkdownRemark(limit: 1000, sort: { fields: frontmatter___id }, filter: { fileAbsolutePath: { regex: "/kompetenzen/" } }) {
                    edges {
                        node {
                            frontmatter {
                                path
                                title
                            }
                            fileAbsolutePath
                        }
                    }
                }
            }
        `
    );

    const competents = {};

    data.competents.edges.forEach(edge => {
        const { fileAbsolutePath, frontmatter } = edge.node;
        const competenceFile = fileAbsolutePath.replace(/^.*\/([^/]+)\.md$/, '$1');
        competents[competenceFile] = frontmatter;
    });

    return data.anwaelte.edges.map(edge => ({
        ...edge.node.frontmatter,
        competents: edge.node.frontmatter.competents.map(key => {
            if (typeof competents[key] === 'undefined') {
                return {
                    path: null,
                    title: key,
                };
            }
            return competents[key];
        }),
    }));
};
